import Script from 'next/script';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { VideoContent } from '../../../amplienceTypes/schemas/exported/section-video-schema';
import Attributes from './Attributes';

const S = {
  EmbedContainer: styled.div`
    position: relative;
    padding-top: 56.25%;
  `,

  Iframe: styled.iframe`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
};

export const ContentBlockVideo = ({ video, attributes }: VideoContent): ReactElement => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (video?.schema && video.id) {
    const { clip, ...rest } = video.schema;
    const videoSchema = {
      '@context': 'http://schema.org/',
      '@type': 'VideoObject',
      ...rest,
      hasPart: clip,
    };

    return (
      <>
        <Script
          id="section-video-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(videoSchema) }}
        />
        <Attributes
          {...attributes}
          additionalClassNames="contentBlock contentBlock-video js-contentBlock"
          style={video.padding ? { margin: '4%', width: 'auto' } : {}}
        >
          {/* Check DOM loaded because of external video fetch causing hydration issue for ssr */}
          {loaded && (
            <S.EmbedContainer>
              {video.type === 'YouTube' && (
                <S.Iframe
                  title="youtube content video iframe"
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${video.id}?modestbranding=1&rel=0${
                    video.autoplay ? '&autoplay=1' : ''
                  }`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
              {video.type === 'Vimeo' && (
                <S.Iframe
                  title="vimeo content video iframe"
                  width="100%"
                  height="100%"
                  src={`https://player.vimeo.com/video/${video.id}/${
                    video.autoplay ? '?muted=1&autoplay=1&loop=1' : ''
                  }`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
            </S.EmbedContainer>
          )}
        </Attributes>
      </>
    );
  }

  return <></>;
};
