import type { ReactElement } from 'react';
import { useRef, useState, useEffect, Fragment, useCallback } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';
import type { DataAttribute } from '../../../../amplienceTypes/partials/exported/element-attributes-schema';
import type { ContentSection } from '../../../../amplienceTypes/schemas/exported/section-schema';
import { SectionTemplate } from './SectionTemplate';
import { colors, media } from '../../../shared/core/styles';

const S = {
  Wrapper: styled(SectionTemplate)`
    && {
      position: relative;
      background-color: ${colors.WHITE_SMOKE_GREY};

      .col--carousel {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100vw;
        transform: translate3d(0, 0, 0);

        @media ${media.greaterThan('lg')} {
          padding-bottom: calc(3.2vw - 20px);
        }

        @media ${media.greaterThan('xl')} {
          .has-open-sideNavigation & {
            width: calc(100vw - 250px);
          }
        }

        + .col--carousel {
          display: none;
        }

        &::after {
          content: '';
          display: inline-block;
          height: 20px;
          width: 1px;
          margin-left: -1px;
        }
      }

      .contentBlockList {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        margin: 0 auto;
        overflow-x: auto;
        padding-bottom: 20px;
        max-width: min-content;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          appearance: none;
          height: 2px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: rgba(0 0 0 / 90%);
          box-shadow: 0 0 1px ${colors.HOTSPOT_BORDER};
        }

        &::-webkit-scrollbar-track {
          background-color: rgba(0 0 0 / 10%);
          margin: 0 20px;

          @media ${media.greaterThan('sm')} {
            margin: 0 36px;
          }

          @media ${media.greaterThan('lg')} {
            margin: 0 3.2vw;
          }
        }

        &::after {
          content: '';
          flex-shrink: 0;
          height: 1px;
          width: 1px;
          margin-left: -1px;
        }
      }

      .contentBlock--category {
        position: relative;
        width: 38vw;
        margin-right: 20px;
        scroll-snap-align: start;
        scroll-margin: 20px;
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 0;

        &:first-child {
          margin-left: 20px;
        }

        @media ${media.greaterThan('sm')}, print {
          min-width: 160px;
          width: 16.15vw;
          scroll-snap-align: center;
          scroll-margin: 0 36px;
          margin-right: 36px;

          &:first-child {
            margin-left: 36px;
          }
        }

        @media ${media.greaterThan('lg')}, print {
          margin-right: 3.2vw;

          &:first-child {
            margin-left: 3.2vw;
          }
        }

        @media ${media.greaterThan('xl')}, print {
          .has-open-sideNavigation & {
            min-width: 110px;
            width: calc(16.15vw - 50px);
          }
        }

        &::before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        .contentBlock-picture,
        .contentBlock-body {
          transform: translate3d(0, 0, 0);
          position: absolute;
          inset: 0;
        }

        .contentBlock-bodyAlign {
          padding: 0;
        }

        .contentBlock-body-title {
          margin: 0;
          font-weight: 700;
          color: ${colors.WHITE};
          text-transform: uppercase;
          text-shadow: 0 0 8px rgba(48 48 48 / 30%);
          font-size: 12px;
          font-size: 3.75vw;

          @media ${media.greaterThan('sm')}, print {
            font-size: 14px;
            letter-spacing: 0.035em;
          }

          @media ${media.greaterThan('lg')}, print {
            font-size: 14px;
            font-size: 1.3672vw;
            letter-spacing: 0.0034vw;
          }

          @media ${media.greaterThan('lg')}, print {
            .has-open-sideNavigation & {
              font-size: 1.0989vw;
            }
          }
        }

        .contentBlock-body-links {
          margin: 0;
          opacity: 0;
        }

        .contentBlock-body-link {
          position: absolute;
          margin: 0;
          inset: 0;
        }

        &.contentBlock--dark {
          .contentBlock-body-title {
            color: ${colors.NERO_GREY};
            text-shadow: 0 0 8px rgba(255 255 255 / 30%);
          }
        }

        .has-mouse & {
          .contentBlock-picture {
            transition: filter 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            filter: brightness(0.9);

            @media (prefers-reduced-motion) {
              transition: none;
            }
          }

          .contentBlock-picture-image {
            transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            transform: scale(1.0001);
          }

          &:hover,
          &:focus {
            .contentBlock-picture {
              filter: brightness(1);
            }

            .contentBlock-picture-image {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  `,

  Header: styled.div`
    && {
      margin: 0 20px;
      padding: 20px 0;

      @media ${media.greaterThan('sm')}, print {
        margin: 0 36px;
      }

      @media ${media.greaterThan('lg')}, print {
        margin: 0 3.2vw;
        padding-top: calc(3.2vw - 12px);
      }
    }
  `,

  Title: styled.h2`
    && {
      font-weight: 900;
      text-transform: uppercase;
      font-size: 6.25vw;

      @media ${media.greaterThan('sm')}, print {
        font-size: 30px;
      }
    }
  `,

  Navigation: styled.div`
    && {
      display: flex;
      margin-top: 16px;
    }
  `,

  NavigationLabel: styled.span`
    && {
      font-size: 13px;
      color: ${colors.SFSC_CHAT_LIGHT_GREY};
    }
  `,

  NavigationTab: styled.button`
    && {
      text-transform: uppercase;
      font-size: 14px;
      margin-left: 13px;
      padding: 0 0 2px;
      line-height: 1;

      &:hover,
      &:focus,
      &.is-active {
        padding-bottom: 0;
        border-bottom: 2px solid currentcolor;
      }
    }
  `,
};

export interface CategoriesAccessibilityInterface {
  ariaRole?: string;
  ariaLabel?: string;
}

export const Categories = (
  props: ContentSection & CategoriesAccessibilityInterface
): ReactElement => {
  const { column, attributes, ariaLabel } = props;
  const [categories, setCategories] = useState<DataAttribute[]>();
  const [activeCategory, setActiveCategory] = useState<string>();
  const [columnRefs, setColumnRefs] = useState<Array<HTMLElement>>([]);
  const prevCategory = useRef<string>();

  const activateCategory = (category?: string) => {
    setActiveCategory(category);
  };

  const updateColumnRefs = useCallback((element: HTMLDivElement) => {
    if (element) {
      setColumnRefs(prevRef => [...prevRef, element]);
    }
  }, []);

  useEffect(() => {
    const attrs: DataAttribute[] = [];

    column?.forEach(col => {
      const dataAttribute = col.attributes?.dataAttributes?.find(
        attribute => attribute.name === 'data-name'
      );

      if (dataAttribute) {
        attrs.push(dataAttribute);
      }
    });
    setCategories(attrs);

    const category = attributes?.dataAttributes?.find(
      dataAttribute => dataAttribute.name === 'data-active'
    )?.value;

    setActiveCategory(category);
  }, [column, attributes]);

  useEffect(() => {
    if (activeCategory) {
      const activeCarousel =
        columnRefs.find(element => element?.dataset.name === activeCategory) || columnRefs[0];
      const previousCarousel = columnRefs.find(
        element => element?.dataset.name === prevCategory.current
      );

      if (activeCarousel !== previousCarousel) {
        const prevContentBlockList =
          previousCarousel?.querySelector<HTMLElement>('.js-contentBlockList');
        const prevContentBlock =
          previousCarousel?.querySelectorAll<HTMLElement>('.js-contentBlock');
        const activeContentBlockList =
          activeCarousel?.querySelector<HTMLElement>('.js-contentBlockList');
        const activeContentBlock =
          activeCarousel?.querySelectorAll<HTMLElement>('.js-contentBlock');

        if (
          previousCarousel &&
          prevContentBlockList &&
          prevContentBlock &&
          activeContentBlockList &&
          activeContentBlock
        ) {
          prevContentBlockList.style.scrollSnapType = 'none';

          const timeline = gsap.timeline({
            onComplete: () => {
              activeContentBlockList.style.scrollSnapType = 'none';
            },
          });

          timeline.to(prevContentBlock, {
            duration: 0.4,
            autoAlpha: 0,
            x: -30,
            stagger: 0.1,
            ease: 'power2.in',
          });
          timeline.fromTo(
            activeContentBlock,
            { autoAlpha: 0, x: 30 },
            {
              onStart: () => {
                activeContentBlockList.style.scrollSnapType = 'none';
                // Sonarqube is looking gsap.set() as non-compliant object overwrite
                // BEGIN-NOSCAN
                gsap.set(activeCarousel, { position: 'absolute', display: 'block', opacity: 1 });
                // END-NOSCAN
                activeContentBlockList.scrollLeft = prevContentBlockList.scrollLeft;
              },
              duration: 0.4,
              autoAlpha: 1,
              x: 0,
              stagger: 0.1,
              ease: 'power2.out',
              onComplete: () => {
                // BEGIN-NOSCAN
                gsap.set(previousCarousel, { display: 'none' });
                gsap.set(activeCarousel, { position: 'relative' });
                // END-NOSCAN
              },
            },
            '-=0.4'
          );
        }

        prevCategory.current = activeCategory;
      }
    }
  }, [activeCategory, columnRefs]);

  const componentTitle = attributes?.dataAttributes?.find(attr => attr.name === 'data-label-title');

  return (
    <S.Wrapper
      updateColumnRefs={updateColumnRefs}
      {...props}
      ariaLabel={componentTitle?.value || ariaLabel}
    >
      <S.Header>
        {componentTitle && <S.Title>{componentTitle.value}</S.Title>}

        <S.Navigation>
          {attributes?.dataAttributes?.map((attribute, i) =>
            attribute.name === 'data-label-filter' ? (
              <S.NavigationLabel key={i}>{attribute.value}</S.NavigationLabel>
            ) : (
              <Fragment key={i} />
            )
          )}

          {categories &&
            categories.map((category, i) => (
              <S.NavigationTab
                key={i}
                className={`${category.value === activeCategory ? 'is-active' : ''}`}
                type="button"
                onClick={() => activateCategory(category.value)}
              >
                {category.value}
              </S.NavigationTab>
            ))}
        </S.Navigation>
      </S.Header>
    </S.Wrapper>
  );
};
