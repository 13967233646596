import styled, { css } from 'styled-components';
import { colors, media } from '../../../../shared/core/styles';

export const styles = {
  ProductSigningContainer: css<{
    $galleryZoomIn: boolean;
    $productImageGalleryHeight: number;
    $productImageGalleryWidth: number;
  }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    gap: 8px;
    right: 10px;
    bottom: 10px;
    z-index: 20;

    &.productSigning--pdpBottom {
      display: none;

      @media ${media.lessThan('sm')} {
        position: absolute;
        top: -50px;
        display: block;

        i {
          display: none;
        }
      }

      @media ${media.greaterThan('lg')} {
        display: flex;
        left: -170px;
        top: -10px;

        &.productSigning--sticky {
          left: -180px;
          top: 10px;
        }

        .productSigning--txt {
          padding: 4px;
          height: 20px;
          width: 150px;
          text-align: center;
          z-index: 21;
          white-space: nowrap;
        }
      }

      i {
        position: absolute;
        right: ${({ $productImageGalleryWidth }) => $productImageGalleryWidth - 190}px;
      }
    }

    &.productSigning--pdpTop {
      display: flex;
      margin-top: 0;
      transform-origin: top right;
      right: 26px;
      top: 18px;
      z-index: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 1 : 501)};

      @media ${media.lessThan('sm')} {
        &.productSigning--sticky {
          top: ${({ $productImageGalleryHeight }) => -$productImageGalleryHeight - 0}px;
          right: 10px;
        }
      }

      @media ${media.greaterThan('sm')} {
        display: none;
      }

      .productSigning--txt {
        display: none;
      }

      i {
        width: 50px;
        height: 50px;
      }
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
  ProductSigning: css`
    position: relative;
    min-height: 20px;
    min-width: 80px;

    &.productSigning--txt {
      padding: 4px;
      height: 20px;
      width: 150px;
      text-align: center;
      z-index: 21;
      white-space: nowrap;
    }

    &.productSigning--img {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      z-index: 21;
    }
  `,
};

export const S = {
  ProductSigningsWrapper: styled.div`
    display: block;
  `,
  ProductSigningContainer: styled.div<{
    $galleryZoomIn: boolean;
    $productImageGalleryHeight: number;
    $productImageGalleryWidth: number;
  }>`
    ${styles.ProductSigningContainer}
  `,
  ProductSigning: styled.div<{ $discountSigning: boolean; $backgroundColor?: string | null }>`
    ${styles.ProductSigning}
    ${({ $discountSigning, $backgroundColor }) => {
      if ($backgroundColor) {
        return css`
          background-color: ${$discountSigning ? colors.RRP_RED : $backgroundColor};
        `;
      }

      return css`
        background-color: ${$discountSigning ? colors.RRP_RED : colors.SIGNINGS_BLUE};
      `;
    }}
      text-align: center;
  `,
  ProductSigningLabel: styled.span<{ $textColor?: string | null }>`
    display: block;
    font-weight: 700;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    letter-spacing: -0.3px;
    line-height: 1;
    font-size: 15px;
    color: ${({ $textColor }) => $textColor || colors.WHITE};
    width: 100%;
    white-space: normal;

    &.productSigning--sale {
      font-size: 22px;
      white-space: nowrap;
    }

    &.productSigning-label--short {
      font-size: 17px;
      line-height: 0.9em;
    }

    &.productSigning-label--long {
      font-size: 13px;
      line-height: 0.9em;
    }

    &.productSigning-label--medium {
      font-size: 13px;
    }

    &.productSigning-label--short.productSigning-label--noWrap,
    &.productSigning-label--short.productSigning-label--singleLine {
      font-size: 19px;
    }
  `,
  ProductImageSigning: styled.div`
    ${styles.ProductSigning}

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  `,
};
