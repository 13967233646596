import type { ReactElement } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../shared/core/styles';
import { ProductName } from './ProductName';

import type { ProductDetailInfo as ProductDetailInfoSchema } from '../../../../amplienceTypes/schemas/imported/product-detail-info-schema';
import type { ProductStyleVariantsStateProps } from './ProductColor';
import { ProductColor } from './ProductColor';
import { ProductSignings } from './productSignings/ProductSignings';
import { SizeSelector } from './SizeSelector';
import { SizeGuideBlock } from './SizeGuideBlock';
import { FindInStore } from './storeStockLocator/FindInStore';
import { ProductNotPurchasableMessage } from './ProductNotPurchasableMessage';
import { ProductUSPs } from './ProductUSPs';
import { SizeProvider } from '../../../../utilities/context/dynamic/SizeContext';
import { useGalleryContext } from '../../../../utilities/context/dynamic/GalleryContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { usePDPDynamicContext } from '../../../../utilities/context/dynamic/PDPDynamicContext';
import { useLayoutDesignContext } from '../../../../utilities/context/dynamic/LayoutDesignContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { ProductReturnFees } from './ProductReturnFees';
import { ProductDeliveryOptions } from './ProductDeliveryOptions';
import type { ProductPriceHybrisProps } from './productPrice/ProductPrice';
import { ProductPrice } from './productPrice/ProductPrice';

const S = {
  ProductDetailInfoWrapper: styled.div<{
    $sideNavOpened: boolean;
    $hasStickyProductImageGallery?: boolean;
  }>`
    --min-width: 360px;
    --padding: 12px;

    height: 100%;
    min-width: var(--min-width);
    width: ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery
        ? 'calc((100vw / 3) - (var(--side-nav-width) / 2) - var(--padding) * 2)'
        : '100%'};
    padding: 0 var(--padding);
    transition: width 250ms ease;
    box-sizing: border-box;
    background-color: ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery ? 'white' : 'transparent'};
    z-index: 0;

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media ${({ $hasStickyProductImageGallery: x }) => media.lessThan(x ? 'lg' : 'sm')} {
      width: 100%;
    }

    @media ${media.greaterThan('lg')} {
      --padding: 0px;

      ${({ $hasStickyProductImageGallery }) => css`
        ${!$hasStickyProductImageGallery ? 'width: 420px;' : ''}
        position: ${$hasStickyProductImageGallery ? 'relative' : 'absolute'};
        right: ${$hasStickyProductImageGallery ? '0px' : '36px'};
      `}
      display: table;
      top: 0;
      background-color: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? 'white' : 'transparent'};
      box-sizing: content-box;
    }

    @media ${media.greaterThan('xl')} {
      ${({ $hasStickyProductImageGallery }) =>
        !$hasStickyProductImageGallery &&
        css`
          width: 420px;
        `}
      right: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '0px' : '36px'};
    }

    @media (min-width: 1440px) {
      right: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '0px' : '36px'};
    }
  `,

  ProductDetailInfoPanel: styled.div<{
    $galleryZoomIn: boolean;
    $hasStickyProductImageGallery?: boolean;
  }>`
    @media ${media.greaterThan('lg')} {
      display: table-cell;
      position: relative;
      ${({ $hasStickyProductImageGallery }) =>
        !$hasStickyProductImageGallery &&
        css`
          filter: drop-shadow(-20px 10px 10px rgba(48 48 48 / 10%));
        `}
      width: 100%;
      max-width: 240px;
      vertical-align: middle;
      z-index: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 0 : 501)};
    }

    @media ${media.greaterThan('xl')} {
      max-width: 360px;
    }
  `,

  ProductDetailInfoInner: styled.div<{ $hasStickyProductImageGallery?: boolean }>`
    position: relative;
    padding-bottom: 22px;

    @media ${media.greaterThan('sm')} {
      ${({ $hasStickyProductImageGallery }) => css`
        max-width: ${$hasStickyProductImageGallery ? 'initial' : '500px'};
        margin: ${$hasStickyProductImageGallery ? 'initial' : '0 auto'};
        padding: ${$hasStickyProductImageGallery ? '20px 20px' : 'initial'};
      `}
    }

    @media ${media.greaterThan('lg')} {
      margin-bottom: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '24px' : '10%'};
      padding-bottom: 0;
    }

    transform-style: preserve-3d;
    backface-visibility: hidden;
  `,

  ProductTitlePriceContainer: styled.div`
    display: block;
    height: auto;
    width: 100%;

    /* RRP Tooltip require overflow visible
    overflow: hidden;
    transform-style: preserve-3d;
    */
    backface-visibility: hidden;
  `,
};

export const ProductDetailControl = ({
  productName,
  productPrice,
  productSignings,
  productSizeSelector,
  productColor,
  productCountdownTimer,
  productStyleVariantInfo,
  productFindInStore,
  productNotPurchasableMessage,
  productSizeGuide,
  productDeliveryOptions,
}: ProductDetailInfoSchema &
  ProductStyleVariantsStateProps &
  ProductPriceHybrisProps): ReactElement => {
  const {
    product: {
      isGiftCard,
      comingSoon: staticComingSoon,
      purchasable: staticPurchasable,
      showStoreStockLocator: staticShowStoreStockLocator,
      signings,
      price: staticPrice,
      fromPrice: staticFromPrice,
      intermediaryPrice: staticIntermediaryPrice,
      formattedLowestPrice: staticFormattedLowestPrice,
      code,
      color,
      deliveryOptions,
      fiberRanking,
    },
  } = usePDPContext();

  const {
    dynamicProductData: {
      comingSoon: dynamicComingSoon,
      purchasable: dynamicPurchasable,
      price: dynamicPrice,
      fromPrice: dynamicFromPrice,
      intermediaryPrice: dynamicIntermediaryPrice,
      formattedLowestPrice: dynamicFormattedLowestPrice,
      showStoreStockLocator: dynamicShowStoreStockLocator,
    },
  } = usePDPDynamicContext();

  const comingSoon = dynamicComingSoon ?? staticComingSoon;
  const purchasable = dynamicPurchasable ?? staticPurchasable;
  const price = dynamicPrice ?? staticPrice;
  const showStoreStockLocator = dynamicShowStoreStockLocator ?? staticShowStoreStockLocator;
  const fromPrice = dynamicFromPrice ?? staticFromPrice;
  const intermediaryPrice = dynamicIntermediaryPrice ?? staticIntermediaryPrice;
  const formattedLowestPrice = dynamicFormattedLowestPrice ?? staticFormattedLowestPrice;

  const { galleryZoomIn } = useGalleryContext();
  const { sideNavOpened } = useNavActionContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const [isOutOfStockSizeSelected, setIsOutOfStockSizeSelected] = useState(false);

  return (
    <S.ProductDetailInfoWrapper
      className="product-detail-info-wrapper"
      suppressHydrationWarning
      $sideNavOpened={sideNavOpened}
      $hasStickyProductImageGallery={hasStickyProductImageGallery}
    >
      <S.ProductDetailInfoPanel
        $galleryZoomIn={galleryZoomIn}
        $hasStickyProductImageGallery={hasStickyProductImageGallery}
      >
        <S.ProductDetailInfoInner $hasStickyProductImageGallery={hasStickyProductImageGallery}>
          <S.ProductTitlePriceContainer>
            {productName?.content && (
              <ProductName
                {...productName.content}
                productStyleVariantInfo={productStyleVariantInfo}
              />
            )}
            {productPrice?.content && (
              <ProductPrice
                {...productPrice.content}
                productStyleVariantInfo={productStyleVariantInfo}
                price={price}
                fromPrice={fromPrice}
                intermediaryPrice={intermediaryPrice}
                formattedLowestPrice={formattedLowestPrice}
              />
            )}
          </S.ProductTitlePriceContainer>
          {productColor?.content && (
            <ProductColor {...productColor.content} code={code} color={color} />
          )}
          {(signings || productStyleVariantInfo || fiberRanking) && productSignings?.content && (
            <ProductSignings
              {...productSignings.content}
              {...productPrice?.content}
              signingPosition="Bottom"
              productStyleVariantInfo={productStyleVariantInfo}
              price={price}
              fromPrice={fromPrice}
              intermediaryPrice={intermediaryPrice}
              formattedLowestPrice={formattedLowestPrice}
            />
          )}
          <SizeProvider>
            {!comingSoon && !isGiftCard && productSizeGuide?.content && (
              <SizeGuideBlock labelProps={productSizeGuide.content} />
            )}
            {!purchasable && !comingSoon && productNotPurchasableMessage?.content && (
              <ProductNotPurchasableMessage {...productNotPurchasableMessage.content} />
            )}
            {purchasable && productSizeSelector?.content && (
              <SizeSelector
                {...(productSizeGuide?.content ?? {})}
                {...productSizeSelector.content}
                productCountdownTimer={!comingSoon ? productCountdownTimer : undefined}
                isOutOfStockSizeSelected={isOutOfStockSizeSelected}
                setIsOutOfStockSizeSelected={setIsOutOfStockSizeSelected}
              />
            )}
            {!isGiftCard && showStoreStockLocator && !comingSoon && productFindInStore?.content && (
              <FindInStore {...productFindInStore.content} isHidden={isOutOfStockSizeSelected} />
            )}
            {!isOutOfStockSizeSelected && <ProductReturnFees />}
            {!isOutOfStockSizeSelected &&
              purchasable &&
              !isGiftCard &&
              showStoreStockLocator &&
              !dynamicComingSoon && <ProductUSPs />}
            {!isOutOfStockSizeSelected &&
              !isGiftCard &&
              purchasable &&
              deliveryOptions?.deliveryOptions &&
              deliveryOptions.deliveryOptions.length > 0 && (
                <ProductDeliveryOptions
                  deliveryOptions={deliveryOptions.deliveryOptions}
                  content={productDeliveryOptions?.content}
                />
              )}
          </SizeProvider>
        </S.ProductDetailInfoInner>
      </S.ProductDetailInfoPanel>
    </S.ProductDetailInfoWrapper>
  );
};
