import type { ReactElement } from 'react';
import Image from 'next/legacy/image';
import type { ProductSignings as ProductSigningsSchema } from '../../../../../amplienceTypes/schemas/imported/product-signings-schema';
import { colors } from '../../../../shared/core/styles';
import { useComponentSize } from '../../../../../utilities/dom';
import { useGalleryContext } from '../../../../../utilities/context/dynamic/GalleryContext';
import { useLayoutDesignContext } from '../../../../../utilities/context/dynamic/LayoutDesignContext';
import type { StyleVariant } from '../../../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../../../utilities/context/static/PDPContext';
import { imageBlurData } from '../../../../../utilities/constants/base64Images';
import { FiberRankingSigning } from '../FiberRankingSigning';
import { S } from './styles';

export type SigningPosition = 'Top' | 'Bottom';

export interface ProductSigningsHybrisProps {
  signingPosition: SigningPosition;
  productStyleVariantInfo?: StyleVariant | null;
  formattedLowestPrice?: string | null;
}

export const ProductSignings = ({
  limit = 0,
  signingPosition,
  productStyleVariantInfo,
  formattedLowestPrice,
}: ProductSigningsHybrisProps & ProductSigningsSchema): ReactElement => {
  const { galleryZoomIn } = useGalleryContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const {
    product: { signings },
  } = usePDPContext();

  const { height: productImageGalleryHeight, width: productImageGalleryWidth } =
    useComponentSize('.product-image-gallery');

  const productSigningContainerClassName = [`productSigning--pdp${signingPosition}`]
    .concat(hasStickyProductImageGallery ? 'productSigning--sticky' : '')
    .join(' ')
    .trim();

  if (productStyleVariantInfo) {
    const styleVariantFromPrice = parseFloat(productStyleVariantInfo.unformattedFromPrice || '0');
    const styleVariantBasePrice = parseFloat(productStyleVariantInfo.unformattedBasePrice || '0');

    let styleVariantScore = 0;

    try {
      const styleVariantFiberRanking = JSON.parse(
        productStyleVariantInfo?.productFiberRankingJson || '{}'
      );

      styleVariantScore = styleVariantFiberRanking?.score;
    } catch (err) {}

    return (
      <S.ProductSigningsWrapper>
        <S.ProductSigningContainer
          className={productSigningContainerClassName}
          $galleryZoomIn={galleryZoomIn}
          $productImageGalleryHeight={productImageGalleryHeight || 0}
          $productImageGalleryWidth={productImageGalleryWidth || 0}
        >
          {productStyleVariantInfo.signing && productStyleVariantInfo.signing.showOnPdp && (
            <S.ProductSigning
              data-testid="text-signing"
              $discountSigning={styleVariantFromPrice > styleVariantBasePrice}
              className="productSigning--txt"
              $backgroundColor={
                productStyleVariantInfo.signing.label === 'SALE'
                  ? colors.RRP_RED
                  : productStyleVariantInfo.signing.backgroundColour
              }
            >
              <S.ProductSigningLabel
                className={productStyleVariantInfo.signing.cssClasses || ''}
                $textColor={productStyleVariantInfo.signing.textColour}
                data-cs-capture=""
              >
                {productStyleVariantInfo.signing.label}
              </S.ProductSigningLabel>
            </S.ProductSigning>
          )}
          <FiberRankingSigning score={styleVariantScore} />
        </S.ProductSigningContainer>
      </S.ProductSigningsWrapper>
    );
  }

  return (
    <S.ProductSigningsWrapper>
      <S.ProductSigningContainer
        className={productSigningContainerClassName}
        $galleryZoomIn={galleryZoomIn}
        $productImageGalleryHeight={productImageGalleryHeight || 0}
        $productImageGalleryWidth={productImageGalleryWidth || 0}
      >
        {signings
          ?.filter(signing => signing?.showOnPdp)
          .sort((a, b) => (b?.priority || 0) - (a?.priority || 0))
          .map((signing, index) => {
            if (!signing || index > limit) {
              return <></>;
            }

            return signing.renderAsText || signing.discountSigning ? (
              <S.ProductSigning
                data-testid={`${signing.discountSigning ? 'discount-signing' : 'text-signing'}`}
                $discountSigning={signing.discountSigning ?? true}
                className="productSigning--txt"
                $backgroundColor={
                  formattedLowestPrice ? colors.RRP_RED : signing.productSigningBackgroundColour
                }
                key={index}
              >
                <S.ProductSigningLabel
                  className={signing.labelCssClasses || ''}
                  $textColor={signing.productSigningTextColour}
                >
                  {signing.label}
                </S.ProductSigningLabel>
              </S.ProductSigning>
            ) : (
              <S.ProductImageSigning
                data-testid="image-signing"
                className="productSigning--img"
                key={index}
              >
                <Image
                  src={signing.image?.url || ''}
                  alt={signing.label || ''}
                  layout="fill"
                  blurDataURL={imageBlurData}
                  placeholder="blur"
                  loading="lazy"
                  priority={false}
                  lazyBoundary="100px"
                />
              </S.ProductImageSigning>
            );
          })}
        <FiberRankingSigning />
      </S.ProductSigningContainer>
    </S.ProductSigningsWrapper>
  );
};
