import type { ReactElement } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ProductImageGallery } from './productImageGallery/ProductImageGallery';
import { ProductDetailControl } from './productDetailControl/ProductDetailControl';
import { ProductSignings } from './productDetailControl/productSignings/ProductSignings';
import type { ProductDetailInfo as ProductDetailInfoSchema } from '../../../amplienceTypes/schemas/imported/product-detail-info-schema';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { PDPDynamicContextProvider } from '../../../utilities/context/dynamic/PDPDynamicContext';
import { useAppContext } from '../../../utilities/context/static/AppContext';

import { addProductToRecentlyViewedLocalStorage } from '../../../utilities/helpers';
import { useGalleryContext } from '../../../utilities/context/dynamic/GalleryContext';

const S = {
  ProductDetailWrapper: styled.div`
    position: relative;
    pointer-events: all;
  `,
};

export const ProductDetailInfo = (props: ProductDetailInfoSchema): ReactElement => {
  const { productImageGallery, productSignings, productVideo } = props;
  const { locale, country } = useAppContext();
  const { product } = usePDPContext();
  const { code } = product;
  const { styleVariantsSelectedIndex, productStyleVariantIndex, styleVariants } =
    useGalleryContext();

  if (product.url) {
    addProductToRecentlyViewedLocalStorage(country, product);
  }

  const productStyleVariantInfo = useMemo(
    () =>
      styleVariantsSelectedIndex !== productStyleVariantIndex && styleVariants
        ? styleVariants[styleVariantsSelectedIndex]
        : undefined,
    [styleVariants, styleVariantsSelectedIndex, productStyleVariantIndex]
  );

  return (
    <S.ProductDetailWrapper data-cs-capture="" data-code={code}>
      {productSignings?.content && (
        <ProductSignings
          {...productSignings.content}
          signingPosition="Top"
          productStyleVariantInfo={productStyleVariantInfo}
        />
      )}

      {productImageGallery?.content && productVideo && (
        <ProductImageGallery {...productImageGallery.content} productVideo={productVideo} />
      )}

      <PDPDynamicContextProvider code={code} locale={locale}>
        <ProductDetailControl {...props} productStyleVariantInfo={productStyleVariantInfo} />
      </PDPDynamicContextProvider>
    </S.ProductDetailWrapper>
  );
};
