import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type MouseEvent,
  type ReactElement,
} from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { media, colors, breakpoints } from '../../../shared/core/styles';
import type { ProductImageProperty, CarouselImageProperty } from './ProductImage';
import { ProductImage, preferredImageProfiles, createProfileRegExp } from './ProductImage';
import { ProductZoomImage } from './ProductZoomImage';
import { Arrow } from './Arrow';
import { DotNavigation } from './DotNavigation';
import type { ProductImageGallery as ProductImageGallerySchema } from '../../../../amplienceTypes/schemas/imported/product-image-gallery-schema';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useGalleryContext } from '../../../../utilities/context/dynamic/GalleryContext';
import { useMediaQueryContext } from '../../../../utilities/context/dynamic/MediaQueryContext';
import { useLayoutDesignContext } from '../../../../utilities/context/dynamic/LayoutDesignContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { ProductVideoPlayButton } from './ProductVideoPlayButton';
import { VariantProductVideoPlayButton } from './VariantProductVideoPlayButton';
import type { ProductVideo as ProductVideoSchema } from '../../../../amplienceTypes/schemas/imported/product-video-schema';
import type { ProductVideoProps } from './ProductVideo';
import { NavigationType, VideoMode } from '../../../../utilities/graphql/codegen';
import { ThumbnailsWrapper } from './ThumbnailsWrapper';
import { useComponentSize } from '../../../../utilities/dom';
import type { Tk21 } from '../../../shared/CRO/tk21';

const ProductVideo = dynamic<ProductVideoProps>(
  () => import('./ProductVideo').then(mod => mod.ProductVideo),
  { ssr: false }
);

export interface ProductImageGalleryHybrisProps {
  productVideo: {
    content: ProductVideoSchema;
    [k: string]: unknown;
  };
}

export type AnimationDirection = 'left' | 'right';

const S = {
  ProductImageGallery: styled.div<{
    $hasStickyProductImageGallery?: boolean;
    $hasFlyoutNavigation: boolean;
    $galleryZoomIn: boolean;
  }>`
    ${({ $hasStickyProductImageGallery }) => css`
      top: ${$hasStickyProductImageGallery ? '0px' : 'initial'};
      z-index: ${$hasStickyProductImageGallery ? '-1' : 'initial'};
      align-self: ${$hasStickyProductImageGallery ? 'flex-start' : 'initial'};
    `}
    height: ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery ? 'calc(100vh - var(--header-height))' : '100vw'};
    background-color: ${colors.IMAGE_GREY};
    text-align: center;
    width: ${({ $hasStickyProductImageGallery, $galleryZoomIn }) =>
      $hasStickyProductImageGallery && !$galleryZoomIn ? 'calc(42px + (100% / 3) * 2)' : '100%'};
    cursor: url('/_fes/${process.env.BUILD_TIME}/img/zoom/zoom-in-cursor.cur') 18 18, default;
    pointer-events: all;
    transition: max-height 0.25s ease;

    @media ${media.lessThan('lg')} {
      position: relative;
      top: initial;
      width: 100%;
      z-index: initial;

      ${({ $hasStickyProductImageGallery }) =>
        // handle square image
        $hasStickyProductImageGallery &&
        css`
          height: 100vw;
        `}
    }

    ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery &&
      css`
        @media ${media.between('lg', 'xl')} {
          width: calc((100% / 3) * 2);
        }
      `}

    @media ${media.greaterThan('sm')} {
      max-height: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '66vh' : '500px'};
    }

    @media ${media.greaterThan('lg')} {
      min-height: 520px;
      max-height: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '100vh' : '675px'};
      width: ${({ $hasStickyProductImageGallery, $galleryZoomIn }) =>
        $hasStickyProductImageGallery && !$galleryZoomIn ? 'calc(42px + (100% / 3) * 2)' : '100%'};
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
  GridCarousel: styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
  `,
  GridCarouselInner: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  GridCarouselInnerItem: styled.div<{
    $isDarkImage: boolean;
    $hasStickyProductImageGallery?: boolean;
  }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    transform: translateX(calc(100% + var(--scrollbar-width)));

    &:first-child {
      transform: translateX(0);
    }

    &::before {
      pointer-events: none;
      content: '';
      background: ${({ $isDarkImage }) =>
        $isDarkImage
          ? `linear-gradient(90deg, ${colors.PRODUCT_IMAGE_LEFT_GREY}, ${colors.PRODUCT_IMAGE_LEFT_GREY} 11%, ${colors.PRODUCT_IMAGE_LEFT_GREY}00 31%, ${colors.PRODUCT_IMAGE_RIGHT_GREY}00 70%, ${colors.PRODUCT_IMAGE_RIGHT_GREY} 90% )`
          : `linear-gradient(90deg, ${colors.PRODUCT_IMAGE_LEFT_LIGHT_GREY}, ${colors.PRODUCT_IMAGE_LEFT_LIGHT_GREY} 11%, ${colors.PRODUCT_IMAGE_BG_DARK_GREY}00 31%, ${colors.PRODUCT_IMAGE_BG_LIGHT_GREY}00 70%, ${colors.PRODUCT_IMAGE_RIGHT_LIGHT_GREY} 90%);`};
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 2366px;
      transform: translateX(-50%);
      left: 50%;
      height: 100%;
      border-left: 25vw solid
        ${({ $isDarkImage }) =>
          $isDarkImage ? colors.PRODUCT_IMAGE_LEFT_GREY : colors.PRODUCT_IMAGE_LEFT_LIGHT_GREY};
      border-right: 25vw solid
        ${({ $isDarkImage }) =>
          $isDarkImage ? colors.PRODUCT_IMAGE_RIGHT_GREY : colors.PRODUCT_IMAGE_RIGHT_LIGHT_GREY};
    }

    @media ${media.greaterThan('xxxl')} {
      &::before {
        width: ${({ $hasStickyProductImageGallery }) =>
          $hasStickyProductImageGallery ? '100%' : '2366px'};
      }
    }
  `,

  ZoomPanel: styled.div<{ $galleryZoomIn: boolean; $hasStickyProductImageGallery?: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: url('/_fes/${process.env.BUILD_TIME}/img/zoom/zoom-out-cursor.cur') 18 18, default;
    z-index: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 1 : -1)};
    opacity: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 1 : 0)};
    overflow: auto;
    user-select: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=');

    @media ${media.lessThan('lg')} {
      display: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 'block' : 'none')};
      width: 100%;
      width: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '100%' : '100vw'};
    }

    @media ${media.greaterThan('lg')} {
      position: absolute;
      width: auto;
      height: auto;
      right: 0;
      bottom: 0;
      overflow: hidden;
      transition: 600ms;
    }
  `,

  ZoomImage: styled.div`
    position: absolute;
    inset: 0;
    overflow: scroll;
    user-select: none;

    @media ${media.greaterThan('sm')} {
      overflow: hidden;
    }
  `,

  ZoomClose: styled.button`
    position: fixed;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background-color: ${colors.NERO_GREY};
    border: none;
    outline: none;

    &::before {
      color: ${colors.WHITE};
      font-family: gstar-icons;
      content: '\\F13F';
    }

    @media ${media.greaterThan('sm')} {
      display: none;
    }

    @media ${media.greaterThan('lg')} {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      display: block;
    }
  `,

  MobileVideoPlayButton: styled.div`
    z-index: 20;
    position: absolute;
    text-align: center;
    width: 55px;
    bottom: 20px;
    left: 5px;
    display: block;

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,
};

export const ProductImageGallery = ({
  autoSlide,
  closeV2,
  arrowLeftV2,
  arrowRightV2,
  productVideo,
}: ProductImageGalleryHybrisProps & ProductImageGallerySchema): ReactElement => {
  const { pushToDataLayer } = useDataLayerContext();
  const {
    product: { simplifiedImages, videos, code, shouldUseDarkBackgroundImgs = true },
  } = usePDPContext();
  const {
    galleryZoomIn,
    setGalleryZoomIn,
    styleVariantsSelectedIndex,
    productStyleVariantIndex,
    styleVariants,
  } = useGalleryContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const {
    configuration: { navigationType },
  } = useStaticContext();
  const { isMobile, isTablet, isDesktop } = useMediaQueryContext();

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [videoActive, setVideoActive] = useState<boolean>(false);
  const [timerStopped, stopTimer] = useState<boolean>(false);
  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [autoplay, setAutoplay] = useState(false);
  const [{ tk21 }, setVariationOptions] = useState<{ tk21: Tk21 }>({
    tk21: {
      translations: {
        playButtonText: '',
        pauseButtonText: '',
      },
      experienceId: 0,
      variationIsControl: true,
      variationNumber: 0,
    },
  });
  const { t } = useTranslation('pdp', { keyPrefix: 'productImageGallery' });

  const zoomPanelRef = useRef<HTMLDivElement>(null);
  const zoomImageWrapperRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  const imageGalleryWrapperRef = useRef<HTMLDivElement>(null);
  const gsapQuery = gsap.utils.selector(galleryRef);

  const video = videos?.find(v => v?.mode === VideoMode.Landscape);

  const isAutoSlide = useMemo(
    () =>
      (isMobile && autoSlide?.mobile) ||
      (isTablet && autoSlide?.tablet) ||
      (isDesktop && autoSlide?.desktop) ||
      false,
    [autoSlide?.mobile, autoSlide?.tablet, autoSlide?.desktop, isMobile, isTablet, isDesktop]
  );

  const containerRect = useMemo(() => {
    if (!zoomPanelRef.current || !galleryZoomIn) {
      return null;
    }

    return zoomPanelRef.current.getBoundingClientRect();
  }, [zoomPanelRef, galleryZoomIn]);

  const productStyleVariantInfo = useMemo(
    () =>
      styleVariantsSelectedIndex !== productStyleVariantIndex && styleVariants
        ? styleVariants[styleVariantsSelectedIndex]
        : undefined,
    [styleVariants, styleVariantsSelectedIndex, productStyleVariantIndex]
  );

  const styleVariant = useMemo(() => {
    if (productStyleVariantInfo?.images) {
      const { thumbnailAltText, xlarge, large } = productStyleVariantInfo.images;
      const productImage = {
        altText: thumbnailAltText ?? '',
        url: xlarge || large || '',
      };
      const newImage: CarouselImageProperty = {
        original: productImage,
        wide: productImage,
      };

      return newImage;
    }

    return null;
  }, [productStyleVariantInfo]);

  const productImageList = useMemo((): CarouselImageProperty[] => {
    const profileRegExp = createProfileRegExp(!!shouldUseDarkBackgroundImgs);

    if (simplifiedImages?.entry) {
      const imageListObj: ProductImageProperty[] = [];
      const wideImageListObj: ProductImageProperty[] = [];

      simplifiedImages.entry.forEach(obj => {
        if (!!obj?.key && obj?.value && obj?.value?.url) {
          const profileName = obj.key;

          if (profileName.match(profileRegExp) && preferredImageProfiles.includes(profileName)) {
            const imageObj = {
              url: obj.value.url,
              profile: profileName,
              altText: obj.value.altText || '',
            };

            if (profileName.includes('W')) {
              wideImageListObj.push(imageObj);
            } else {
              imageListObj.push(imageObj);
            }
          }
        }
      });

      imageListObj.sort((a, b) => (a.profile && b.profile && a.profile < b.profile ? -1 : 1));

      if (tk21.variationNumber) {
        imageListObj.splice(2, 0, {
          profile: '',
          url: '',
        });
      }

      const carouselImageListObj: CarouselImageProperty[] = [];

      imageListObj.forEach(original => {
        const wideImage = wideImageListObj.find(
          wideItem => `${original.profile}W` === wideItem.profile
        );

        carouselImageListObj.push({
          original,
          wide: wideImage,
        });
      });

      return carouselImageListObj;
    }

    return [];
  }, [tk21.variationNumber, shouldUseDarkBackgroundImgs, simplifiedImages?.entry]);

  const { height: productDetailInfoHeight } = useComponentSize('.product-detail-info-wrapper', {
    condition: !hasStickyProductImageGallery,
  });

  useEffect(() => {
    if (
      !hasStickyProductImageGallery &&
      imageGalleryWrapperRef.current &&
      productDetailInfoHeight &&
      isDesktop
    ) {
      imageGalleryWrapperRef.current.style.maxHeight = `${productDetailInfoHeight}px`;
    }
  }, [productDetailInfoHeight, hasStickyProductImageGallery, isDesktop]);

  const slideToNextImage = useCallback(
    (newIndex: number) => {
      if (currentImageIndex === newIndex) {
        return;
      }

      let nextIndex = newIndex;

      if (tk21.variationNumber && galleryZoomIn && nextIndex === 2) {
        if (currentImageIndex < nextIndex && productImageList[nextIndex + 1]) {
          nextIndex = 3;
        } else {
          nextIndex = 0;
        }

        if (currentImageIndex > nextIndex) {
          nextIndex = 1;
        }
      }

      let direction: AnimationDirection = 'left';

      if (nextIndex < currentImageIndex) {
        if (
          currentImageIndex - nextIndex <=
          nextIndex + productImageList.length - currentImageIndex
        ) {
          direction = 'right';
        }
      } else if (
        currentImageIndex + productImageList.length - nextIndex <
        nextIndex - currentImageIndex
      ) {
        direction = 'right';
      }

      gsap.to(gsapQuery('.gallery-item')[nextIndex], {
        x: direction === 'left' ? '100%' : '-100%',
        duration: 0,
      });
      gsap.to(gsapQuery('.gallery-item')[currentImageIndex], {
        x: direction === 'left' ? '-100%' : '100%',
        duration: 0.6,
      });
      gsap.to(gsapQuery('.gallery-item')[nextIndex], {
        x: '0',
        duration: 0.6,
      });

      setCurrentImageIndex(nextIndex);

      if (tk21.variationNumber) {
        if (nextIndex === 2) {
          setVideoActive(true);
          setAutoplay(true);
        } else {
          setAutoplay(false);
        }
      }
    },
    [tk21.variationNumber, galleryZoomIn, productImageList, currentImageIndex, gsapQuery]
  );

  useEffect(() => {
    const autoSlideToNextImg = () => {
      const nextIndex = (currentImageIndex + 1) % productImageList.length;

      slideToNextImage(nextIndex);
    };

    let timerId = 0;
    const delay = tk21.variationNumber && currentImageIndex === 2 ? 18000 : 5000;

    if (isAutoSlide && !timerStopped) {
      timerId = window.setTimeout(autoSlideToNextImg, delay);
    }

    return () => {
      if (timerId !== 0) {
        window.clearTimeout(timerId);
      }
    };
  }, [
    currentImageIndex,
    isAutoSlide,
    productImageList.length,
    slideToNextImage,
    timerStopped,
    tk21.variationNumber,
  ]);

  const openGalleryZoomPanel = () => {
    if (galleryZoomIn) {
      return;
    }

    setGalleryZoomIn(true);

    if (hasStickyProductImageGallery) {
      window.scrollTo({ top: 0 });

      if (window.innerWidth < parseInt(breakpoints.lg, 10)) {
        document.body.classList.add('no-scroll');
      }
    }
  };

  const closeGalleryZoomPanel = () => {
    if (!galleryZoomIn) {
      return;
    }

    setGalleryZoomIn(false);
    document.body.classList.remove('no-scroll');
  };

  const onSlideToNextImage = async (
    nextIndex: number,
    el: 'thumbnail' | 'arrow',
    productCode: string
  ) => {
    stopTimer(true);
    slideToNextImage(nextIndex);

    pushToDataLayer({
      events: {
        category: el,
        action: `click ${el === 'arrow' ? 'arrow ' : ''}${nextIndex + 1}`,
        label: String(nextIndex + 1).padStart(3, `${shouldUseDarkBackgroundImgs ? 'M' : 'Z'}00`),
      },
      product: {
        materialNumber: productCode,
      },
      event: 'thumbnail-click',
    });
  };

  useEffect(() => {
    const refEl = galleryRef.current;
    const startHandler = (event: TouchEvent) => {
      if (galleryZoomIn) {
        return;
      }

      setTouchStartX(event?.changedTouches?.[0]?.pageX || NaN);
    };
    const endHandler = (event: TouchEvent) => {
      if (galleryZoomIn) {
        return;
      }

      const touchEndX = event?.changedTouches?.[0]?.pageX || NaN;

      if (!Number.isNaN(touchStartX) && !Number.isNaN(touchEndX)) {
        stopTimer(true);

        const delta = Math.abs(touchEndX - touchStartX);

        if (delta < 18) {
          return;
        }

        if (touchEndX > touchStartX) {
          const nextIndex =
            currentImageIndex === 0 ? productImageList.length - 1 : currentImageIndex - 1;

          slideToNextImage(nextIndex);
        } else if (touchEndX < touchStartX) {
          const prevIndex =
            currentImageIndex === productImageList.length - 1 ? 0 : currentImageIndex + 1;

          slideToNextImage(prevIndex);
        }
      }
    };

    if (refEl) {
      refEl.addEventListener('touchstart', startHandler, { passive: true });
      refEl.addEventListener('touchend', endHandler, { passive: true });
    }

    return () => {
      if (refEl) {
        refEl.removeEventListener('touchstart', startHandler);
        refEl.removeEventListener('touchend', endHandler);
      }
    };
  }, [
    galleryRef,
    touchStartX,
    currentImageIndex,
    slideToNextImage,
    productImageList.length,
    galleryZoomIn,
  ]);

  useEffect(() => {
    const storeVariationOptions = (event: Event) => {
      if (event instanceof CustomEvent && event.detail) {
        setVariationOptions({ ...event.detail });
      }
    };

    document.addEventListener('TK21-test-is-running', storeVariationOptions);
    document.dispatchEvent(new CustomEvent('TK21-experience-options-request'));

    return () => document.removeEventListener('TK21-test-is-running', storeVariationOptions);
  }, []);

  const setZoomPanelScrollXCenter = (e: MouseEvent) => {
    stopTimer(true);
    openGalleryZoomPanel();

    if (!zoomPanelRef.current) {
      return;
    }

    if (zoomImageWrapperRef.current) {
      zoomPanelRef.current.style.display = 'block';

      if (zoomImageWrapperRef.current.offsetWidth < parseInt(breakpoints.sm, 10)) {
        zoomImageWrapperRef.current.style.left = '0';
        zoomImageWrapperRef.current.scrollLeft =
          (zoomImageWrapperRef.current.scrollWidth - zoomImageWrapperRef.current.offsetWidth) / 2;
      } else {
        zoomPanelRef.current.scrollLeft = 0;

        const panelBounds = containerRect;

        if (panelBounds) {
          const mouseX = e.clientX - panelBounds.left;
          const mouseY = e.clientY - panelBounds.top;

          zoomImageWrapperRef.current.style.left = `-${
            ((2000 - panelBounds.width) / panelBounds.width) * mouseX
          }px`;
          zoomImageWrapperRef.current.style.top = `-${
            ((2000 - panelBounds.height) / panelBounds.height) * mouseY
          }px`;
        }
      }
    }
  };

  const onMouseMoveOnZoomPanel = (e: MouseEvent) => {
    const panelBounds = containerRect;

    if (
      panelBounds &&
      panelBounds.width >= parseInt(breakpoints.sm, 10) &&
      zoomImageWrapperRef.current
    ) {
      const mouseX = e.clientX - panelBounds.left;
      const mouseY = e.clientY - panelBounds.top;

      zoomImageWrapperRef.current.style.left = `-${
        ((2000 - panelBounds.width) / panelBounds.width) * mouseX
      }px`;
      zoomImageWrapperRef.current.style.top = `-${
        ((2000 - panelBounds.height) / panelBounds.height) * mouseY
      }px`;
    }
  };

  const onPlayButtonClick = () => {
    if (tk21.experienceId) {
      pushToDataLayer({
        event: 'abtest.event',
        'abtest.action': tk21.experienceId,
        'abtest.label': `click_play_${tk21.variationIsControl ? 'control' : 'variant'}${
          tk21.variationIsControl ? '' : `_${tk21.variationNumber}`
        }`,
      });
    }

    if (tk21.variationNumber === 2 && currentImageIndex !== 2) {
      stopTimer(true);
      slideToNextImage(2);
    } else {
      setVideoActive(true);
    }
  };

  return (
    <S.ProductImageGallery
      className="product-image-gallery"
      ref={imageGalleryWrapperRef}
      $hasStickyProductImageGallery={hasStickyProductImageGallery}
      $hasFlyoutNavigation={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      $galleryZoomIn={galleryZoomIn}
    >
      <S.GridCarousel data-qubit-id="image-gallery">
        <S.GridCarouselInner ref={galleryRef}>
          <>
            {productImageList?.map((productImage, index) =>
              tk21.variationNumber && video && index === 2 ? (
                <S.GridCarouselInnerItem
                  className="gallery-item"
                  data-testid="image-hero"
                  key={index}
                  $isDarkImage={!!shouldUseDarkBackgroundImgs}
                  onClick={setZoomPanelScrollXCenter}
                  $hasStickyProductImageGallery={hasStickyProductImageGallery}
                >
                  <ProductVideo
                    {...productVideo?.content}
                    active={videoActive}
                    setActive={setVideoActive}
                    autoplay={autoplay}
                    video={video}
                    variationOptions={{ tk21 }}
                  />
                </S.GridCarouselInnerItem>
              ) : (
                <S.GridCarouselInnerItem
                  className="gallery-item"
                  data-testid="image-hero"
                  key={index}
                  $isDarkImage={!!shouldUseDarkBackgroundImgs}
                  onClick={setZoomPanelScrollXCenter}
                  $hasStickyProductImageGallery={hasStickyProductImageGallery}
                >
                  {styleVariant ? (
                    <ProductImage lazyLoad={index !== currentImageIndex} {...styleVariant} />
                  ) : (
                    <ProductImage lazyLoad={index !== currentImageIndex} {...productImage} />
                  )}
                </S.GridCarouselInnerItem>
              )
            )}
          </>
        </S.GridCarouselInner>
        {[
          { direction: -1, ariaLabel: arrowLeftV2 || t('arrowLeft') },
          { direction: 1, ariaLabel: arrowRightV2 || t('arrowRight') },
        ].map((entry, idx) => (
          <Arrow
            testId={`image-gallery-arrow-${entry.direction === -1 ? 'left' : 'right'}`}
            direction={entry.direction}
            key={idx}
            ariaLabel={entry.ariaLabel}
            galleryZoomIn={galleryZoomIn || false}
            clickHandler={() => {
              const nextIndex = Math.abs(
                (productImageList.length + (currentImageIndex + 1 * entry.direction)) %
                  productImageList.length
              );

              onSlideToNextImage(nextIndex, 'arrow', code);
            }}
          />
        ))}

        <DotNavigation
          galleryZoomIn={galleryZoomIn || false}
          currentImageIndex={currentImageIndex}
          clickHandlers={productImageList.map(
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            (_, index) => _ => onSlideToNextImage(index, 'thumbnail', code)
          )}
        />
      </S.GridCarousel>
      <ThumbnailsWrapper
        code={code}
        galleryZoomIn={galleryZoomIn}
        onSlideToNextImage={onSlideToNextImage}
        styleVariant={!!styleVariant}
        currentImageIndex={currentImageIndex}
        productImageList={productImageList}
        videoClickHandler={() => setVideoActive(true)}
        hasVideo={!!video}
        videoActive={videoActive}
      />
      {video && (
        <S.MobileVideoPlayButton>
          {tk21.variationIsControl && <ProductVideoPlayButton onClick={onPlayButtonClick} />}
          {tk21.variationNumber === 2 && currentImageIndex !== 2 && !galleryZoomIn && (
            <VariantProductVideoPlayButton onClick={onPlayButtonClick} />
          )}
        </S.MobileVideoPlayButton>
      )}
      <S.ZoomPanel
        data-testid="image-zoom-panel"
        ref={zoomPanelRef}
        $galleryZoomIn={galleryZoomIn || false}
        onMouseMove={onMouseMoveOnZoomPanel}
        onClick={closeGalleryZoomPanel}
        $hasStickyProductImageGallery={hasStickyProductImageGallery}
      >
        <S.ZoomImage ref={zoomImageWrapperRef}>
          <ProductZoomImage
            url={productImageList[currentImageIndex]?.original.url}
            altText={productImageList[currentImageIndex]?.original.altText}
            isShow={galleryZoomIn}
          />
        </S.ZoomImage>

        <S.ZoomClose
          data-testid="image-zoom-close"
          aria-label={closeV2 || t('close')}
          onClick={closeGalleryZoomPanel}
        />
      </S.ZoomPanel>
      {video && videoActive && tk21.variationIsControl && (
        <ProductVideo
          {...productVideo?.content}
          active={videoActive}
          autoplay={autoplay}
          setActive={setVideoActive}
          video={video}
          variationOptions={{ tk21 }}
        />
      )}
    </S.ProductImageGallery>
  );
};
