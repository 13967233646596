import type { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { ProductNotPurchasableMessage as ProductNotPurchasableMessageSchema } from '../../../../amplienceTypes/schemas/imported/product-not-purchasable-message-schema';
import { Typography } from '../../../shared/core/typography/Typography';
import { colors } from '../../../shared/core/styles';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { usePDPDynamicContext } from '../../../../utilities/context/dynamic/PDPDynamicContext';

const S = {
  ProductNotPurchasableMessageWrapper: styled.div`
    margin: 1em 0 1.5em;
    padding: 0;
  `,
  ProductNotPurchasableMessage: styled(Typography)`
    text-align: center;
    color: ${colors.ERROR_RED};
  `,
};

export const ProductNotPurchasableMessage = ({
  onlyReasonV2,
  notOnlyReasonV2,
}: ProductNotPurchasableMessageSchema): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productNotPurchasableMessage' });
  const {
    product: { stockInformation: staticStockInformation },
  } = usePDPContext();
  const {
    dynamicProductData: { stockInformation: dynamicStockInformation },
  } = usePDPDynamicContext();
  const stockInformation = dynamicStockInformation ?? staticStockInformation;

  return (
    <S.ProductNotPurchasableMessageWrapper data-testid="summary-product-not-purchasable-message">
      <S.ProductNotPurchasableMessage component="div" variant="body" data-cs-capture="">
        {stockInformation?.stockQuantity === 0
          ? onlyReasonV2 || t('onlyReason')
          : notOnlyReasonV2 || t('notOnlyReason')}
      </S.ProductNotPurchasableMessage>
    </S.ProductNotPurchasableMessageWrapper>
  );
};
