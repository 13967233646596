import type { ReactElement } from 'react';
import styled from 'styled-components';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';

interface FiberRanking {
  score?: number;
}

const S = {
  FiberRankingIcon: styled.i<{ $matchedLocale: string }>`
    display: inline-block;
    background: ${({ $matchedLocale }) =>
      `url('/_fes/${process.env.BUILD_TIME}/img/svg/fiber-ranking-badge-${$matchedLocale}.svg')`};
    width: 80px;
    height: 80px;
  `,
};

export const FiberRankingSigning = ({ score }: FiberRanking): ReactElement => {
  const SCORE_THRESHOLD = 2.5;
  const {
    configuration: { fiberRanking: fiberRankingEnabled },
  } = useStaticContext();
  const {
    product: { fiberRanking },
  } = usePDPContext();
  const currentProductScore =
    score !== undefined ? score : fiberRanking?.fiberRanking?.productFiberRankingData?.score || 0;
  const { locale } = useAppContext();
  const scrollToLegend = () => {
    const fiberRankingLegend = document.querySelector('[data-testid="fiber-ranking"]');

    fiberRankingLegend?.scrollIntoView({ behavior: 'smooth' });
  };
  let matchedLocale = '';

  if (
    locale.startsWith('en') ||
    locale.startsWith('nl') ||
    locale.startsWith('de') ||
    locale.startsWith('es') ||
    locale.startsWith('fr') ||
    locale.startsWith('ja') ||
    locale.startsWith('ko')
  ) {
    matchedLocale = locale.substring(0, 2);
  }

  return fiberRankingEnabled && currentProductScore >= SCORE_THRESHOLD && matchedLocale.length ? (
    <S.FiberRankingIcon $matchedLocale={matchedLocale} onClick={scrollToLegend} />
  ) : (
    <></>
  );
};
