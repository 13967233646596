import type { ReactElement } from 'react';
import styled from 'styled-components';
import { colors } from '../../../shared/core/styles';

const S = {
  Play: styled.button`
    display: flex;
    align-items: center;
    position: absolute;
    height: 32px;
    left: 11px;
    bottom: -3px;
    padding: 0 16px;
    background-color: rgb(255 255 255 / 60%);
    border-radius: 16px;
    text-wrap: nowrap;
    font-size: 12px;
    border: none;
    z-index: 20;
    opacity: 1;
    cursor: pointer;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 12px;
      border-color: transparent transparent transparent ${colors.BLACK};
      margin-right: 9px;
    }
  `,
};

interface VariantProductVideoPlayButtonProps {
  onClick: () => void;
}

export const VariantProductVideoPlayButton = ({
  onClick,
}: VariantProductVideoPlayButtonProps): ReactElement => (
  <S.Play onClick={onClick}>Play video</S.Play>
);
