import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { colors, media } from '../../../shared/core/styles';

const S = {
  InfoTipTitle: styled.h3`
    color: ${colors.WHITE};
    background-color: ${colors.NERO_GREY};
    font-size: 16px;
    text-align: center;
    padding: 13px 0;
    display: block;
    line-height: 1em;

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,
  TipContent: styled.div`
    width: auto;
    min-width: auto;
    padding: 30px;
    background: ${colors.WHITE};
    font-style: normal;
    font-size: 12px;
    color: ${colors.ACCESSIBILITY_GREY};
  `,
  TipTitle: styled.h5`
    font-size: 18px;
    font-weight: 700;

    &.desktop-only {
      display: none;
    }

    @media ${media.greaterThan('sm')} {
      &.desktop-only {
        display: block;
      }
    }
  `,
  TipDescription: styled.p`
    color: ${colors.ACCESSIBILITY_GREY};
    margin-top: 8px;
    margin-bottom: 16px;

    &.desktop-only {
      display: none;
    }

    @media ${media.greaterThan('sm')} {
      &.desktop-only {
        display: block;
      }
    }
  `,
  TipButtonContainer: styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
  `,
  Close: styled.button`
    color: ${colors.BLACK};
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    padding: 0;
    z-index: 52;
    font-size: 16px;
    outline: 0;
    border: none;

    &.desktop-only {
      display: none;
    }

    @media ${media.greaterThan('sm')} {
      &.desktop-only {
        display: block;
      }
    }
  `,
  IconClose: styled.i`
    &::before {
      content: '\\F13E';
      font-family: gstar-icons;
      font-style: normal;
    }
  `,
  ConfirmButton: styled.button`
    font-weight: 700;
    display: inline-block;
    padding: 13px 9px;
    border-width: 2px;
    border-style: solid;
    border-radius: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    text-align: center;
    line-height: inherit;
    cursor: pointer;
    background-clip: border-box;
    box-shadow: 0 0 0 0;
    color: ${colors.WHITE};
    border-color: ${colors.ONYX_GREY};
    background-color: ${colors.NERO_GREY};
    width: 100%;

    @media ${media.greaterThan('sm')} {
      width: 215px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: ${colors.ONYX_GREY};
    }

    @media (prefers-reduced-motion) {
      transition: 75ms color ease-in-out;
    }
  `,
};

export const ProductShippingEstimatesDisclaimer = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productShippingEstimates' });

  return (
    <>
      <S.InfoTipTitle>{t('modalTitle')}</S.InfoTipTitle>

      <S.Close className="desktop-only" onClick={onClick}>
        <S.IconClose />
      </S.Close>

      <S.TipContent>
        <S.TipTitle>{t('deliveryTime')}</S.TipTitle>

        <S.TipDescription>{t('deliveryTimeDescription')}</S.TipDescription>

        <S.TipTitle className="desktop-only">{t('deliveryOptions')}</S.TipTitle>

        <S.TipDescription className="desktop-only">
          {t('deliveryOptionsDescription')}
        </S.TipDescription>

        <S.TipButtonContainer>
          <S.ConfirmButton onClick={onClick} data-testid="shipping-estimates-disclaimer-close">
            {t('confirm')}
          </S.ConfirmButton>
        </S.TipButtonContainer>
      </S.TipContent>
    </>
  );
};
